<template>
  <div class="app-container">
    <el-form :inline="true">
      <el-form-item>
        <el-button size="small" type="primary" @click="back">返回</el-button>
      </el-form-item>
    </el-form>
    <div class="detail-container">
      <el-form :inline="true">
        <el-form-item label="交易编号："> {{ historyData.tradeNo }} </el-form-item>
      </el-form>
      <el-form :inline="true">
        <el-form-item label="说明：">
          {{ historyData.nickName || '--' }}在{{ parseTime(historyData.tradeTime) }} {{ type
          }}{{ historyData.tradeAmount }}</el-form-item
        >
      </el-form>
      <el-form :inline="true">
        <el-form-item label="变更金额：">
          {{ $route.query.isIncome == 1 ? '+￥' + historyData.tradeAmount : '-￥' + historyData.tradeAmount }}
        </el-form-item>
      </el-form>
      <el-form :inline="true">
        <el-form-item label="账户余额："> ￥{{ historyData.remainingAmount }} </el-form-item>
      </el-form>
      <el-form :inline="true">
        <el-form-item label="支付时间："> {{ parseTime(historyData.tradeTime) }} </el-form-item>
      </el-form>
    </div>

    <div class="change">
      <div :class="['changeSelect', changeType === 'detail' && 'active']" @click="change('detail')">订单详情</div>
      <div
        v-if="$route.query.tradeType == 3"
        :class="['changeSelect', changeType === 'statistics' && 'active']"
        @click="change('statistics')"
      >
        款式统计
      </div>
    </div>

    <div v-if="changeType === 'detail'" class="detail-container" style="margin-top: 20px">
      <el-form :inline="true" v-if="historyData.thirdpartTradeNo">
        <el-form-item label="流水号："> {{ historyData.thirdpartTradeNo }} </el-form-item>
      </el-form>
      <el-form :inline="true">
        <el-form-item label="支付方式："> {{ payType(historyData.paymentType) }} </el-form-item>
      </el-form>
      <el-form :inline="true">
        <el-form-item label="应付金额：">
          <span v-if="historyData.totalAmount || historyData.realAmount === 0"> ￥{{ historyData.totalAmount }}</span>
        </el-form-item>
      </el-form>
      <el-form :inline="true">
        <el-form-item label="实付金额：">
          <span v-if="historyData.realAmount || historyData.realAmount === 0">￥{{ historyData.realAmount }}</span>
        </el-form-item>
      </el-form>
      <el-form :inline="true">
        <el-form-item label="支付状态："> {{ currentState(historyData.paymentStatus) }} </el-form-item>
      </el-form>
      <el-form :inline="true">
        <el-form-item label="支付时间："> {{ parseTime(historyData.tradeTime) }} </el-form-item>
      </el-form>
    </div>

    <div v-if="changeType === 'statistics'" class="detail-container" style="margin-top: 20px">
      <div class="styleStatistics">
        <div class="styleStatistics_product">产品总计：{{ styleData.totalCount }}</div>
        <div>货款总额：{{ styleData.totalAmount }}</div>
        <div class="ml20">优惠金额：{{ styleData.totalCouponAmount }}</div>
      </div>
      <CommonTable :selection="false" :cols="cols" :infoData="tableData"> </CommonTable>
    </div>
  </div>
</template>

<script>
import { styleCols as cols } from './cols'
import { parseTime } from '@/utils'
import { getDetails } from '@/api/balanceApi'
import { styleStatistical } from '@/api/financial/userCredit'
import { PAYMENT_TYPE_LABELS } from '@/utils/constant/paymentConst'

export default {
  data() {
    return {
      cols,
      tableData: [],
      changeType: 'detail',
      historyData: {},
      styleData: {}
    }
  },
  methods: {
    parseTime,
    change(data) {
      this.changeType = data
    },
    back() {
      this.$router.go(-1)
    }
  },
  computed: {
    type() {
      const { tradeType, isIncome } = this.$route.query
      return {
        1: '充值',
        2: '提现',
        3: '订单支付',
        4: '退款',
        5: '手工调整余额',
        6: '提现审核拒绝退款',
        7: `重新申请运费${isIncome == 1 ? '退' : '补'}款`,
        8: `售后申请运费${isIncome == 1 ? '退' : '补'}款`,
        9: '手工调整总信用额',
        10: '手工调整可用信用额',
        11: '充值信用额度',
        12: '临时额度生成',
        13: '月账单还款',
        14: 'fba订单支付'
      }[tradeType]
    },
    payType() {
      return (type) => {
        return PAYMENT_TYPE_LABELS[type]
      }
    },
    currentState() {
      return (state) => {
        let cur
        switch (state) {
          case 0:
            cur = '未支付'
            break
          case 1:
            cur = '已支付'
            break
          case 2:
            cur = '关闭/取消'
            break
        }
        return cur
      }
    }
  },
  async created() {
    const { id, tradeType, relatedId } = this.$route.query
    try {
      const { code, detail } = await getDetails({ id })
      if (code != 0) return
      this.historyData = detail
    } catch (error) {}
    // tradeType == 3表示是支付订单
    if (tradeType == 3) {
      try {
        const { orderList } = this.historyData || {}
        const { code, detail } = await styleStatistical({ orderId: orderList[0].id })
        if (code != 0) return
        this.styleData = detail || {}
        this.tableData = detail.styleStatisticalList
      } catch (error) {}
    }
  }
}
</script>

<style lang="scss" scoped>
.app-container {
  padding: 20px 20px;
  .detail-container {
    border: 1px solid #ccc;
    padding: 10px;
  }
  .change {
    margin-top: 20px;
    display: flex;
    width: 150px;
    justify-content: space-between;
    .changeSelect {
      cursor: pointer;
    }
    .active {
      color: #6067e2;
    }
  }
  .styleStatistics {
    display: flex;
    margin: 10px 10px 20px 10px;
    .styleStatistics_product {
      margin-right: 20px;
    }
  }
}
</style>
